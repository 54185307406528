import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
  Input,
} from "@mui/material";
import AU from "../Country_Icons/AU.png";
import CN from "../Country_Icons/CN.png";
import EU from "../Country_Icons/EU.png";
import IN from "../Country_Icons/IN.png";
import JP from "../Country_Icons/JP.png";
import USA from "../Country_Icons/USA.png";

import { useEffect, useState } from "react";
import Individual from "./DataCenter/Individual";
import { type } from "@testing-library/user-event/dist/type";

const typoStyle = {
  "& input": {
    borderBottom: "1px solid #e5d3d3",
    display: "inline",
    width: "5ch",
    pb: 0,
    textAlign: "center",
  },
};
function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
export default function Overview({
  passedButton,
  revokeLoadingZohoUS,
  authLoadingZohoUS,
  zohoAuthUS,
  revokeLoadingZohoAU,
  authLoadingZohoAU,
  zohoAuthAU,
  revokeLoadingZohoEU,
  authLoadingZohoEU,
  zohoAuthEU,
  revokeLoadingZohoIN,
  authLoadingZohoIN,
  zohoAuthIN,
  revokeLoadingZohoJP,
  authLoadingZohoJP,
  zohoAuthJP,
  handleAuthenticateZoho,
  handleRevokeZoho,
  checkPoint,
  setCheckPoint,
}) {
  // const [checkPoint, setCheckPoint] = useState({});

  const [historicalFetchDate, setHistoricalFetchDate] = useState();
  const InputWithinText = ({ fieldName, defaultValue }) => {
    return (
      <Input
        onChange={(e) => {
          const sanitizedValue = e.target.value.replace(/[^0-9.]/g, "");
          setCheckPoint((prev) => {
            return {
              ...checkPoint,
              [fieldName]: {
                ...checkPoint?.[fieldName],
                value: sanitizedValue,
              },
            };
          });
        }}
        components={{ Root: "span" }}
        size="small"
        variant="standard"
        value={checkPoint?.[fieldName]?.value}
        // defaultValue={checkPoint?.[fieldName]?.value || defaultValue}
      />
    );
  };

  const [hoverShowBtn, setHoverShowBtn] = useState();
  const [check_points, set_check_points] = useState([
    {
      name: "Non_Zero_Tx_No_Comm_Associated",
      descriptionStart:
        "When no Commission is associated with Transaction where Transaction Amount != 0",
      showTollarance: false,
    },
    {
      name: "Refund",
      descriptionStart: "When any amount is refunded",
      showTollarance: false,
    },
    {
      name: "Tx_Amount_Decrease_From_Last_Recurring_Payment_No_Downgrade",
      descriptionStart: "When Transaction amount reduces more then",
      descriptionEnd:
        "$$ from last recurring payment if no Downgrade occurs between the period",
    },
    {
      name: "Tx_Amount_Increase_But_Eligible_Percent_Not_Increased_From_Last_Recurring",
      descriptionStart:
        "Transaction amount Increased but Eligible amount difference is more then",
      descriptionEnd: "$$ from previous Eligible Amount",
    },
    {
      name: "After_Upgrade_Transaction_Amount_Not_Increased",
      descriptionStart:
        "When User upgraded Licences, but Transaction Amount not increased from Last Recurring Payment",
    },
    {
      name: "Eligible_Amount_Reduced_From_Last_Recurring_Payment",
      descriptionStart: "When Eligible Amount reduce more then",
      descriptionEnd: "$$ from Last Recurring Payment (No Downgrade Occurs)",
    },
    {
      name: "Eligible_Percentage_Reduced_From_Last_Recurring_Payment",
      descriptionStart: "When Eligible Percentage reduce more then",
      descriptionEnd: "% from Last Recurring Payment (No Downgrade Occurs)",
    },
    {
      name: "Eligible_Less_Then_100_For_Upgrade",
      descriptionStart: "When Eligible Percentage reduce more then",
      descriptionEnd: "% from 100% for Upgrade of Licence",
    },
    {
      name: "Extension_Eligible_Percentage_Less_Then_100_For_Extension",
      descriptionStart: "When Eligible Percentage reduce more then",
      descriptionEnd: "% from 100% for Upgrade of Licence",
    },
    {
      name: "Commission_Percentage_For_Upgrade",
      descriptionStart: "When Commission % difference is more then",
      descriptionEnd:
        "% from 25% for Months 0-36 and 15% for 37+ for Licence Upgrade",
    },
    {
      name: "Commission_Percentage_For_Recurring",
      descriptionStart: "When Commission % difference is more then",
      descriptionEnd:
        "% from 25% for Months 0-36 and 15% for 37+ for Recurring Payment",
    },
    {
      name: "Commission_Percentage_Reduces_From_Last_Recurring_Payment",
      descriptionStart: "When Commission % reduces more then",
      descriptionEnd: "% from Last Recurring Payment",
    },
    {
      name: "Commission_Amount_Reduces_From_Last_Recurring_No_Downgrade",
      descriptionStart: "When Commission Amount reduces more then",
      descriptionEnd: "$$ from Last Recurring Payment",
    },
    {
      name: "Commission_Percentage_Below_100_For_Extension",
      descriptionStart: "When Commission % reduces more then",
      descriptionEnd: "% for Extensions. It should be 100%",
    },
  ]);

  return (
    <Box
      sx={{
        py: 1,
        width: "85%",
        mx: "auto",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        mt: 2,
        bgcolor: "#fff",
      }}
    >
      <Box sx={{ px: 4 }}>
        <Typography
          sx={{
            mt: 2,
            mb: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          Authentication
        </Typography>

        <br />

        <Box
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",

            "li:not(:last-child)": {
              margin: "0 0 10px 0",
            },
          }}
        >
          Connections created by you and the administrators of your organization
          are listed here. You can let other users of your organization utilize
          your connections. Changes made to such connections will also affect
          the users who utilize them.
        </Box>
      </Box>
      <Box sx={{ ml: 7, mt: 3, display: "flex" }}>
        {[
          {
            handleAuthenticateZoho,
            handleRevokeZoho,
            hoverShowBtn,
            setHoverShowBtn,
            zohoAuth: zohoAuthUS,
            authLoading: authLoadingZohoUS,
            revokeLoadingZoho: revokeLoadingZohoUS,
            countryCode: "US",
            image: USA,
          },
          {
            handleAuthenticateZoho,
            handleRevokeZoho,
            hoverShowBtn,
            setHoverShowBtn,
            zohoAuth: zohoAuthAU,
            authLoading: authLoadingZohoAU,
            revokeLoadingZoho: revokeLoadingZohoAU,
            countryCode: "AU",
            image: AU,
          },
          {
            handleAuthenticateZoho,
            handleRevokeZoho,
            hoverShowBtn,
            setHoverShowBtn,
            zohoAuth: zohoAuthEU,
            authLoading: authLoadingZohoEU,
            revokeLoadingZoho: revokeLoadingZohoEU,
            countryCode: "EU",
            image: EU,
          },
          {
            handleAuthenticateZoho,
            handleRevokeZoho,
            hoverShowBtn,
            setHoverShowBtn,
            zohoAuth: zohoAuthIN,
            authLoading: authLoadingZohoIN,
            revokeLoadingZoho: revokeLoadingZohoIN,
            countryCode: "IN",
            image: IN,
          },
          {
            handleAuthenticateZoho,
            handleRevokeZoho,
            hoverShowBtn,
            setHoverShowBtn,
            zohoAuth: zohoAuthJP,
            authLoading: authLoadingZohoJP,
            revokeLoadingZoho: revokeLoadingZohoJP,
            countryCode: "JP",
            image: JP,
          },
        ].map((item, index) => (
          <Individual
            key={index}
            handleAuthenticateZoho={item.handleAuthenticateZoho}
            handleRevokeZoho={item.handleRevokeZoho}
            hoverShowBtn={item.hoverShowBtn}
            setHoverShowBtn={item.setHoverShowBtn}
            auth={item.zohoAuth}
            authLoading={item.authLoading}
            revokeLoading={item.revokeLoadingZoho}
            countryCode={item.countryCode}
            img={item.image}
          />
        ))}
      </Box>
      <Box sx={{ m: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: "1rem",
            pb: ".5rem",
          }}
        >
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            Check Points
          </Typography>
          {passedButton}
          {/* <Button
            variant="contained"
            style={{ textTransform: "capitalize" }}
            disabled={
              historicalFetchDate !== formatDate(new Date()) ? false : true
            }
            onClick={async () => {
              console.log({ fromClick: checkPoint });
              return;
              const tolleranceData = {
                apiname:
                  process.env.REACT_APP_EXTENSION_IDENTIFIER +
                  "__Tollerance_Data",
                value: JSON.stringify(checkPoint),
              };
              await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", tolleranceData);

              const scheduleDate = {
                apiname:
                  process.env.REACT_APP_EXTENSION_IDENTIFIER +
                  "__historical_fetch_starts",
                value: formatDate(new Date()),
              };
              setHistoricalFetchDate(formatDate(new Date()));
              await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", scheduleDate);

              const resp = await axios.request({
                url: `https://api.v1.admin.easy-pluginz.com/utils/cronjobs`,
                method: "POST",
                headers: {
                  orgid: orgId, // Org ID
                  apikey: apiKey, // API KEy
                  connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
                },
                data: {
                  reqUrl: encodeURIComponent(
                    process.env.REACT_APP_SANDBOX === "true"
                      ? `https://plugin-easystoretracker.zohosandbox.com/crm/v2/functions/easystoretracker__process_historical_data/actions/execute?auth_type=apikey&zapikey=1001.d8c9461adbe6404a6a75adb538530cad.103554dd33424d3ec758705c09dd58c7`
                      : dataCenterUrl +
                          "/crm/v2/functions/easystoretracker__process_historical_data/actions/execute?auth_type=apikey&zapikey=" +
                          zapiKey
                  ),
                  reqParams: {},
                  reqHeaders: {},
                  reqBody: {},
                  per_page: 50,
                  reqType: "GET",
                },
              });
            }}
          >
            Save and Fetch Historical Data
          </Button> */}
        </Box>

        <Grid container spacing={0.3}>
          {check_points.map((indvCheckPoint, index) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sx={{
                  "&:hover": { bgcolor: "#F8FCFF" },
                  py: 0.5,
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  {" "}
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ py: "1px" }}
                        checked={
                          checkPoint?.[indvCheckPoint?.name]?.checked ===
                          undefined
                            ? true
                            : checkPoint?.[indvCheckPoint?.name]?.checked
                        }
                        onChange={(event) =>
                          setCheckPoint({
                            ...checkPoint,
                            [indvCheckPoint?.name]: {
                              ...checkPoint?.[indvCheckPoint?.name],
                              checked: event.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label={
                      <Typography sx={typoStyle}>
                        {indvCheckPoint?.descriptionStart}
                        {indvCheckPoint?.descriptionEnd && (
                          <Input
                            disabled={
                              checkPoint?.[indvCheckPoint?.name]?.checked ===
                              undefined
                                ? false
                                : !checkPoint?.[indvCheckPoint?.name]?.checked
                            }
                            onChange={(e) => {
                              const sanitizedValue = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              setCheckPoint((prev) => {
                                return {
                                  ...checkPoint,
                                  [indvCheckPoint?.name]: {
                                    ...checkPoint?.[indvCheckPoint?.name],
                                    value: sanitizedValue,
                                  },
                                };
                              });
                            }}
                            components={{ Root: "span" }}
                            size="small"
                            variant="standard"
                            value={checkPoint?.[indvCheckPoint?.name]?.value}
                          />
                        )}
                        {indvCheckPoint?.descriptionEnd}
                      </Typography>
                    }
                  ></FormControlLabel>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}
