import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import PowerOutlinedIcon from "@mui/icons-material/PowerOutlined";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    borderRadius: "8px",
    width: "100%",
    height: "100%",
  },
})(Backdrop);
export default function Individual({
  handleAuthenticateZoho,
  handleRevokeZoho,
  hoverShowBtn,
  setHoverShowBtn,
  auth,
  authLoading,
  revokeLoading,
  countryCode,
  img,
}) {
  return (
    <Box
      onMouseEnter={() => setHoverShowBtn(countryCode)}
      onMouseLeave={() => setHoverShowBtn()}
      sx={{
        width: "13rem",
        height: "8.188rem",
        mr: 2,
        // border: "1px solid #EEE",
        borderRadius: "8px",
        position: "relative",
        backgroundColor: "#F8FCFF",
        p: "16px 40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "&:hover": {
          boxShadow:
            "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)",
        },
      }}
    >
      <Box
        sx={{
          width: "12px",
          height: "12px",
          position: "absolute",
          top: "12px",
          right: "12px",
          backgroundColor: auth ? "green" : "#BDBDBD",
          borderRadius: "50%",
        }}
      ></Box>
      <img src={img} width="70%" />

      <LimitedBackdrop open={hoverShowBtn === countryCode}>
        {auth ? (
          <Button
            onClick={() => handleRevokeZoho(countryCode)}
            variant="contained"
            startIcon={<DoNotDisturbAltOutlinedIcon />}
            sx={{
              backgroundColor: "#FF9800",
              "&:hover": {
                backgroundColor: "#ED6C02",
              },
            }}
          >
            {revokeLoading ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                loading
                <CircularProgress
                  sx={{ color: "white", ml: 1 }}
                  size={17}
                />{" "}
              </Box>
            ) : (
              `Revoke`
            )}
          </Button>
        ) : (
          <Button
            onClick={() => {
              handleAuthenticateZoho(countryCode);
            }}
            variant="contained"
            startIcon={<PowerOutlinedIcon />}
            sx={{
              backgroundColor: "#4CAF50",
              "&:hover": {
                backgroundColor: "#2E7D32",
              },
            }}
          >
            {authLoading ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Verifying{" "}
                <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
              </Box>
            ) : (
              `Authenticate`
            )}
          </Button>
        )}
      </LimitedBackdrop>
    </Box>
  );
}
