import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

export default function LoaderComp({msg}) {
  return (
    <Box sx={{ display:"flex",alignItems:"center",justifyContent:"center",height:"100dvh" }}>
        <Box sx={{ width: "fit-content" }}>
            <Typography sx={{fontWeight:700,fontSize:"1rem"}}>{msg}</Typography>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
        </Box>
    </Box>
  );
}