import React, { useState, useEffect } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";

const ZOHO = window.ZOHO;

const Index = () => {
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [zohoApiKey, setZohoApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        const orgData = {
          apiKeys: [
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL",
          ],
        };
        const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

        setDataCenterUrl(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL"
          ]?.value
            ? orgVariables?.Success?.Content?.[
                process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL"
              ]?.value != "null"
              ? orgVariables?.Success?.Content?.[
                  process.env.REACT_APP_EXTENSION_IDENTIFIER +
                    "__Data_Center_URL"
                ]?.value
              : dataCenterUrl
            : dataCenterUrl
        );
        setZohoApiKey(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY"
          ]?.value
        );
        setOrgId(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID"
          ]?.value
        );
        setZapiKey(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key"
          ]?.value
        );

        /**
         * Business Logic
         */
        setZohoLoaded(true);
      });

      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);
  useEffect(() => {
    if (zohoLoaded) {
      /**
       * Add Business Logic
       */
    }
  }, [zohoLoaded]);

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        Loading..
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    );
  }
  return <>Add Business Logic</>;
};

export default Index;
