import React, { useState, useEffect } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";

const ZOHO = window.ZOHO;
window.ZOHO_MODAL_HEIGHT = "750px";

export default function Buttons() {
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [zohoApiKey, setZohoApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();

  useEffect(() => {
    async function initZoho() {
      // let ButtonPosition= {
      //   "DetailView": {
      //     "EntityId": [
      //       "4731441000006871001"
      //     ],
      //     "Entity": "Leads",
      //     "ButtonPosition": "DetailView"
      //   },
      //   "ListViewEachRecord": {
      //     "Entity": "Leads",
      //     "ButtonPosition": "ListViewEachRecord",
      //     "EntityId": [
      //       "4731441000006871001"
      //     ]
      //   },
      //   "ListViewWithoutRecord": {
      //     "Entity": "Leads",
      //     "ButtonPosition": "ListViewWithoutRecord",
      //     "cvid": "4731441000000087501",
      //     "page": 1,
      //     "EntityId": [
      //       "4731441000006871001",
      //       "4731441000006799001",
      //       "4731441000006796001",
      //       "4731441000006792001",
      //       "4731441000006788026",
      //       "4731441000006462001",
      //       "4731441000006415445",
      //       "4731441000006376063",
      //       "4731441000006376045",
      //       "4731441000006376002",
      //       "4731441000006243449",
      //       "4731441000006243437",
      //       "4731441000006243425",
      //       "4731441000006243412",
      //       "4731441000006181139",
      //       "4731441000005725139",
      //       "4731441000005725090",
      //       "4731441000005725073",
      //       "4731441000005725001",
      //       "4731441000005670116",
      //       "4731441000005670073",
      //       "4731441000005670018",
      //       "4731441000005600029",
      //       "4731441000005179001",
      //       "4731441000005137157",
      //       "4731441000005097189",
      //       "4731441000005046135",
      //       "4731441000003153021",
      //       "4731441000003153020",
      //       "4731441000003153019",
      //       "4731441000003153018",
      //       "4731441000003153017",
      //       "4731441000003153016",
      //       "4731441000003153015",
      //       "4731441000003153014",
      //       "4731441000003153013",
      //       "4731441000003153012",
      //       "4731441000003153011",
      //       "4731441000003153010",
      //       "4731441000003153009",
      //       "4731441000003153008",
      //       "4731441000003153007",
      //       "4731441000003153006",
      //       "4731441000003153005",
      //       "4731441000003153004",
      //       "4731441000003153003",
      //       "4731441000003153002",
      //       "4731441000003151001",
      //       "4731441000003150001",
      //       "4731441000003148179",
      //       "4731441000003148078",
      //       "4731441000003148073",
      //       "4731441000003148068",
      //       "4731441000003133003"
      //     ]
      //   },
      //   "ListView": {
      //     "Entity": "Leads",
      //     "EntityId": [
      //       "4731441000006871001",
      //       "4731441000006799001",
      //       "4731441000006796001"
      //     ],
      //     "ButtonPosition": "ListView"
      //   },
      //   "EditView": {
      //     "Entity": "Leads",
      //     "EntityId": "4731441000006799001",
      //     "Data": {
      //       "Currency": "BDT",
      //       "Custom_Subform": [
      //         {
      //           "Father_s_Name": "Jamal",
      //           "Mother_s_Name": "Momena",
      //           "Name1": "Jihan"
      //         }
      //       ],
      //       "Email_Opt_Out": false,
      //       "Exchange_Rate": 1,
      //       "field_map_check": false,
      //       "field_map_multi_picklist": [],
      //       "First_Name": "Test new",
      //       "Last_Name": "Instawebworks new",
      //       "Layout": {
      //         "name": "Standard",
      //         "id": "4731441000000091055"
      //       },
      //       "Owner": {
      //         "id": "4731441000000297001",
      //         "name": "Nasir Uddin"
      //       },
      //       "Phone": "017663249563256"
      //     },
      //     "ButtonPosition": "EditView"
      //   },
      //   "CreateOrCloneView": {
      //     "Entity": "Leads",
      //     "Data": {
      //       "Currency": "BDT",
      //       "Custom_Subform": [
      //         {
      //           "Father_s_Name": "Jamal",
      //           "Mother_s_Name": "Momena",
      //           "Name1": "Jihan"
      //         }
      //       ],
      //       "Email_Opt_Out": false,
      //       "Exchange_Rate": 1,
      //       "field_map_check": false,
      //       "field_map_multi_picklist": [],
      //       "First_Name": "Test new",
      //       "Last_Name": "Instawebworks new",
      //       "Layout": {
      //         "name": "Standard",
      //         "id": "4731441000000091055"
      //       },
      //       "Owner": {
      //         "id": "4731441000000297001",
      //         "name": "Nasir Uddin"
      //       },
      //       "Phone": "017663249563256"
      //     },
      //     "ButtonPosition": "CreateOrCloneView"
      //   }
      // };
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        console.log("Load Data ", data);
        await ZOHO.CRM.UI.Resize({
          height: window.ZOHO_MODAL_HEIGHT,
          width: "1200",
        });
        const orgData = {
          apiKeys: [
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL",
          ],
        };
        const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

        setDataCenterUrl(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL"
          ]?.value
            ? orgVariables?.Success?.Content?.[
                process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL"
              ]?.value != "null"
              ? orgVariables?.Success?.Content?.[
                  process.env.REACT_APP_EXTENSION_IDENTIFIER +
                    "__Data_Center_URL"
                ]?.value
              : dataCenterUrl
            : dataCenterUrl
        );
        setZohoApiKey(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY"
          ]?.value
        );
        setOrgId(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID"
          ]?.value
        );
        setZapiKey(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key"
          ]?.value
        );

        /**
         * Add Business Logic
         */

        setZohoLoaded(true);
      });
      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);
  useEffect(() => {
    if (zohoLoaded) {
      /**
       * Add Business Logic
       */
    }
    setLoading(false);
  }, [zohoLoaded]);

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        Loading...
      </Backdrop>
    );
  }

  return <>Add Business Logic</>;
}
