import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Grid, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

import Backdrop from "@mui/material/Backdrop";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

const leftBoxStyle = {
  height: 65,
  p: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const ZOHO = window.ZOHO;

const containerStyle = {
  width: "100%",
  height: "450px",
};

const CustomDropdown = ({ totalCount, setNoOfTxs, noOfTxs }) => {
  const [selectedValue, setSelectedValue] = React.useState((prev) =>
    totalCount > noOfTxs ? noOfTxs : totalCount
  );

  useEffect(() => {
    setSelectedValue((prev) => (totalCount > noOfTxs ? noOfTxs : totalCount));
  }, []);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setNoOfTxs(event.target.value);
  };

  if (totalCount > 10) {
    return (
      <FormControl
        variant="standard"
        sx={{ minWidth: 120, "& .MuiInputBase-input": { pb: 0 } }}
        size="small"
      >
        <InputLabel id="demo-simple-select-standard-label">
          No of Txs
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={
            selectedValue != 0
              ? selectedValue
              : totalCount > noOfTxs
              ? noOfTxs
              : totalCount
          }
          onChange={handleChange}
          label="Select Value"
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          {/* <MenuItem value={-1}>All</MenuItem> */}
          {totalCount >= 10 && <MenuItem value={10}>10</MenuItem>}
          {totalCount >= 20 && <MenuItem value={20}>20</MenuItem>}
          {totalCount >= 30 && <MenuItem value={30}>30</MenuItem>}
          {totalCount >= 40 && <MenuItem value={40}>40</MenuItem>}
          {totalCount >= 50 && <MenuItem value={50}>50</MenuItem>}
          {totalCount >= 60 && <MenuItem value={60}>60</MenuItem>}
          {totalCount >= 70 && <MenuItem value={70}>70</MenuItem>}
          {totalCount >= 80 && <MenuItem value={80}>80</MenuItem>}
          {totalCount % 10 !== 0 && totalCount <= 80 && (
            <MenuItem value={totalCount}>{totalCount}</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
  return <></>;
};

export default function SubscriptionHistoryChart({
  data,
  setAllIssueTransactions,
  allIssueTransactions,
}) {
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const [entity, setEntity] = useState();
  const [entityId, setEntityId] = useState();
  //   const [apiKey, setApiKey] = useState();
  //   const [orgId, setOrgId] = useState();
  //   const [zapiKey, setZapiKey] = useState();
  //   const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [chartData, setChartData] = useState([]);
  const [subscription, setSubscription] = useState();
  const [commission, setCommission] = useState();
  const [transaction, setTransaction] = useState();
  const [latestEligablePercentage, setLatestEligablePercentage] = useState(0);
  const [latestCommPercentage, setLatestCommPercentage] = useState(0);
  const [totalTxCount, setTotalTxCount] = useState(0);
  const [totalTxAmount, setTotalTxAmount] = useState(0);
  const [totalCommCount, setTotalCommCount] = useState(0);
  const [totalCommAmount, setTotalCommAmount] = useState(0);
  const [currentTooltipData, setCurrentTooltipData] = useState(null);
  const [subsId, setSubsId] = useState();
  const ZOHO = window.ZOHO;

  // const [totalTxCount, setTotalTxCount] = useState();
  const [noOfTxs, setNoOfTxs] = useState(30);
  const getColor = (bar) => {
    const currentTransactionColor = "black";
    const txHasNoCommAssociated = "red";
    const refundTx = "grey";
    const commReduced = "orange";
    const noIssue = "green";
    const indv = sortedChartDatas[bar.index];
    if (
      (commission?.id === indv.commId && commission?.id !== undefined) ||
      (transaction?.id === indv.txId && transaction?.id !== undefined)
    ) {
      // setCurrentTooltipData(indv);
      return currentTransactionColor;
    }
    if (bar.id == "txAmount") {
      if (indv.txAmount > 0 && indv.commAmount === 0) {
        return txHasNoCommAssociated;
      }

      if (indv.elegibleAmount < 0) {
        return refundTx;
      } else {
        if (indv.commStatus === "Down") {
          return commReduced;
        } else {
          return noIssue;
        }
      }
    }

    return "pink";
  };

  useEffect(() => {
    const initializeData = async () => {
      // setLoading(false);
      let subscriptionId = "";
      if (data?.Entity === "easystoretracker__Store_Commissions") {
        const commissionResp = await ZOHO.CRM.API.getRecord({
          Entity: data?.Entity,
          RecordID: data?.EntityId,
        });
        setCommission(commissionResp?.data?.[0]);

        setTransaction((prev) => ({
          id: commissionResp?.data?.[0]?.easystoretracker__Store_Transaction
            ?.id,
        }));
        subscriptionId =
          commissionResp?.data?.[0]?.easystoretracker__Store_Subscription?.id;
      } else if (data?.Entity === "easystoretracker__Store_Transactions") {
        const transactionResp = await ZOHO.CRM.API.getRecord({
          Entity: data?.Entity,
          RecordID: data?.EntityId,
        });
        setTransaction(transactionResp?.data?.[0]);
        subscriptionId =
          transactionResp?.data?.[0]?.easystoretracker__Store_Subscription?.id;
      } else {
        subscriptionId = data?.EntityId;
      }
      setSubsId(subscriptionId);

      if (subscriptionId !== "" && subscriptionId !== undefined) {
        const subscriptionResp = await ZOHO.CRM.API.getRecord({
          Entity: "easystoretracker__Store_Subscriptions",
          RecordID: subscriptionId,
        });
        setSubscription(subscriptionResp?.data?.[0]);
        // Get Transactions and Commissions
        let allTransactions = [];
        let transactions = await ZOHO.CRM.API.getRelatedRecords({
          Entity: "easystoretracker__Store_Subscriptions",
          RecordID: subscriptionId,
          RelatedList: "easystoretracker__Store_Transactions",
          page: 1,
          per_page: 200,
        });
        allTransactions = [...allTransactions, ...(transactions?.data || [])];
        let page_no = 1;
        while (transactions?.data?.length == 200) {
          page_no = page_no + 1;
          transactions = await ZOHO.CRM.API.getRelatedRecords({
            Entity: "easystoretracker__Store_Subscriptions",
            RecordID: subscriptionId,
            RelatedList: "easystoretracker__Store_Transactions",
            page: page_no,
            per_page: 200,
          });

          allTransactions = [...allTransactions, ...(transactions?.data || [])];
        }

        let transactionMap = {};
        let totalNonZeroTx = 0;
        let totalNonZeroTxAmount = 0;
        let totalComm = 0;
        let totalCommAmount = 0;

        allTransactions?.forEach((indvTx) => {
          if (indvTx?.easystoretracker__Transaction_Amount > 0) {
            totalNonZeroTx += 1;
            totalNonZeroTxAmount +=
              indvTx?.easystoretracker__Transaction_Amount;
          }
          transactionMap[indvTx.Name] = {
            ...transactionMap[indvTx.Name],
            txId: indvTx.id,
            txAmount: indvTx?.easystoretracker__Transaction_Amount || 0,
            txDate: indvTx?.easystoretracker__Transaction_Date_Time,
            elegibleAmount: 0,
            commAmount: 0,
            serviceName: "",
            description: indvTx?.easystoretracker__Description,
            yearsPassed:
              indvTx?.easystoretracker__Months_from_First_Subscription,
          };
        });
        setTotalTxCount(totalNonZeroTx);
        setTotalTxAmount(totalNonZeroTxAmount);
        setChartData(transactionMap);
        let allCommissions = [];
        let commissions = await ZOHO.CRM.API.getRelatedRecords({
          Entity: "easystoretracker__Store_Subscriptions",
          RecordID: subscriptionId,
          RelatedList: "easystoretracker__Store_Commissions",
          page: 1,
          per_page: 200,
        });
        allCommissions = [...allCommissions, ...(commissions?.data || [])];

        page_no = 1;
        while (commissions?.data?.length == 200) {
          page_no = page_no + 1;
          commissions = await ZOHO.CRM.API.getRelatedRecords({
            Entity: "easystoretracker__Store_Subscriptions",
            RecordID: subscriptionId,
            RelatedList: "easystoretracker__Store_Commissions",
            page: 1,
            per_page: 200,
          });

          allCommissions = [...allCommissions, ...(commissions?.data || [])];
        }

        let latestElgPer = 0;
        let latestCommPer = 0;

        allCommissions?.forEach((indvComm) => {
          totalComm += 1;
          totalCommAmount +=
            (indvComm?.easystoretracker__Commission_Amount || 0) *
            (indvComm?.easystoretracker__Eligible_Transaction_Amount0 < 0
              ? -1
              : 1);
          transactionMap[indvComm.easystoretracker__Transaction_ID] = {
            ...transactionMap[indvComm.easystoretracker__Transaction_ID],
            commId: indvComm.id,
            commAmount: indvComm?.easystoretracker__Commission_Amount || 0,
            txDate: indvComm?.easystoretracker__Transaction_Date_Time,
            elegibleAmount:
              indvComm?.easystoretracker__Eligible_Transaction_Amount0 || 0,
            serviceName: indvComm?.easystoretracker__Service_Name || "",
          };
          if (
            latestElgPer === 0 &&
            indvComm?.easystoretracker__Eligible_Transaction_Amount0 > 0 &&
            indvComm?.easystoretracker__Transaction_Description.includes(
              "Recurring payment"
            )
          ) {
            latestElgPer = (
              (indvComm?.easystoretracker__Eligible_Transaction_Amount0 /
                (indvComm?.easystoretracker__Transaction_Amount || 1)) *
              100
            ).toFixed(2);
            latestCommPer = (
              (indvComm?.easystoretracker__Commission_Amount /
                (indvComm?.easystoretracker__Eligible_Transaction_Amount0 ||
                  1)) *
              100
            ).toFixed(2);
          }
        });

        setTotalCommAmount(totalCommAmount);
        setTotalCommCount(totalComm);
        setLatestEligablePercentage(latestElgPer);
        setLatestCommPercentage(latestCommPer);
      }

      // setLoading(false);
    };
    setLoading(true);
    initializeData();
    setLoading(false);
  }, []);

  if (loading || !chartData)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        Loading..
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    );

  let sortedChartDatas0 = Object.values(chartData).sort(function (a, b) {
    return new Date(a.txDate) - new Date(b.txDate);
  });

  let sortedChartDatas = sortedChartDatas0.filter((indv) => {
    if (indv.txAmount !== 0) {
      return true;
    } else {
      return false;
    }
  });
  let lowerIndex = 0;
  let higherIndex = sortedChartDatas.length;
  if (noOfTxs !== -1) {
    // Find Index
    if (transaction?.id) {
      const txIndex = sortedChartDatas.findIndex(
        (indv) => indv.txId == transaction.id
      );

      const paginationCount =
        noOfTxs > sortedChartDatas.length ? sortedChartDatas.length : noOfTxs;

      if (txIndex - paginationCount / 2 < 0) {
        lowerIndex = 0;
        higherIndex = lowerIndex + paginationCount;
      } else {
        lowerIndex = txIndex - paginationCount / 2;
        higherIndex = txIndex + paginationCount / 2;
      }

      if (higherIndex > sortedChartDatas.length) {
        higherIndex = sortedChartDatas.length;
        lowerIndex = higherIndex - noOfTxs;
        if (lowerIndex < 0) {
          lowerIndex = 0;
        }
      }

      sortedChartDatas = sortedChartDatas.slice(lowerIndex, higherIndex);
    } else {
      sortedChartDatas = sortedChartDatas.slice(
        sortedChartDatas.length -
          (sortedChartDatas.length > noOfTxs
            ? noOfTxs
            : sortedChartDatas.length),
        sortedChartDatas.length
      );
    }
  }

  let latestCommissionDate = "";

  sortedChartDatas.forEach((indv, index) => {
    let currentCommPercentage = 0;
    let previousCommPercentage = 0;
    if (latestCommissionDate === "" && indv?.commAmount !== 0) {
      latestCommissionDate = indv?.txDate;
    }
    currentCommPercentage = (
      ((indv?.commAmount || 0) / (indv?.elegibleAmount || 1)) *
      100
    ).toFixed(0);
    if (index !== sortedChartDatas.length - 1) {
      previousCommPercentage = (
        ((sortedChartDatas[index + 1]?.commAmount || 0) /
          (sortedChartDatas[index + 1]?.elegibleAmount || 1)) *
        100
      ).toFixed(0);
    }
    sortedChartDatas[index].currentCommPercentage = currentCommPercentage;
    sortedChartDatas[index].previousCommPercentage = previousCommPercentage;
    if (currentCommPercentage > previousCommPercentage) {
      sortedChartDatas[index].commStatus = "Up";
    } else if (currentCommPercentage === previousCommPercentage) {
      sortedChartDatas[index].commStatus = "Same";
    } else {
      sortedChartDatas[index].commStatus = "Down";
    }
  });

  if (chartData && subsId && sortedChartDatas)
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          bgcolor: "#ffffff",
          p: 2,
        }}
      >
        <Box
          sx={{
            width: "25%",
            bgcolor: "rgba(25, 118, 210, 0.04)",
            borderRadius: "10px",
            p: 2,
            mr: 1.5,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
            Subscription Details
          </Typography>
          <Grid container spacing={1}>
            {/* <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
            Subscription Details
          </Typography> */}
            <Grid item xs={6}>
              <Box sx={{ ...leftBoxStyle, bgcolor: "#F1F8E9" }}>
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Status
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {subscription?.easystoretracker__Status}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ ...leftBoxStyle, bgcolor: "#FFF8E1" }}>
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Elg %
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {latestEligablePercentage}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ ...leftBoxStyle, bgcolor: "#E1F5FE" }}>
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Sub Start
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {subscription?.easystoretracker__Subscription_Start_Date}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ ...leftBoxStyle, bgcolor: "#EDE7F6" }}>
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Latest Comm %
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {latestCommPercentage}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ ...leftBoxStyle, bgcolor: "#F1F8E9" }}>
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Tx/Comm Count
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {totalTxCount + " / " + totalCommCount}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ ...leftBoxStyle, bgcolor: "#FFF8E1" }}>
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Lifetime Comm
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {totalCommAmount.toFixed(2)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  bgcolor: "#E1F5FE",
                  ...leftBoxStyle,
                }}
              >
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Comm Count
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {totalCommCount}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  bgcolor: "#EDE7F6",
                  ...leftBoxStyle,
                }}
              >
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Service
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {subscription?.easystoretracker__Service_Name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  bgcolor: "#F1F8E9",
                  ...leftBoxStyle,
                }}
              >
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Last Comm
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {latestCommissionDate?.split(" ")?.[0] || ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  bgcolor: "#FFF8E1",
                  ...leftBoxStyle,
                }}
              >
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Paid User
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {subscription?.easystoretracker__Paid_Users}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  bgcolor: "#E1F5FE",
                  ...leftBoxStyle,
                }}
              >
                {" "}
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Billing Freq
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {subscription?.easystoretracker__Billing_Frequency}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  bgcolor: "#EDE7F6",
                  ...leftBoxStyle,
                }}
              >
                {" "}
                <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Next Rec
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {subscription?.easystoretracker__Status === "active"
                    ? subscription?.easystoretracker__Next_Recurring_Date
                    : ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "75%",
            bgcolor: "rgba(25, 118, 210, 0.04)",
            borderRadius: "10px",
            height: 480,
            p: 2,
            display: "flex",
          }}
        >
          <Box sx={{ width: "85%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <Typography>
                Tx and Comm Timeline of{" "}
                <strong>
                  {subscription?.easystoretracker__Customer_Company_Name}
                </strong>
              </Typography>
              <CustomDropdown
                totalCount={totalTxCount || 0}
                setNoOfTxs={setNoOfTxs}
                noOfTxs={noOfTxs}
              />
              {data.Entity === "easystoretracker__Store_Transactions" &&
                transaction?.easystoretracker__Has_Issues &&
                allIssueTransactions && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      var config = {
                        Entity: data.Entity,
                        APIData: {
                          id: data.EntityId,
                          easystoretracker__Has_Issues: false,
                          easystoretracker__Issue_Resolved: true,
                        },
                        Trigger: [""],
                      };
                      ZOHO.CRM.API.updateRecord(config).then(function (
                        data
                      ) {});

                      const remainingTx = allIssueTransactions.filter(
                        (indv) => indv.id !== data.EntityId
                      );

                      setAllIssueTransactions({ allIssueTxs: remainingTx });
                    }}
                  >
                    Resolve
                  </Button>
                )}
            </Box>

            <ResponsiveBar
              data={sortedChartDatas}
              keys={["txAmount", "commAmount"]}
              indexBy="txDate"
              margin={{ top: 10, right: 0, bottom: 95, left: 0 }}
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={getColor}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "#38bcb2",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "#eed312",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: "txAmount",
                  },
                  id: "line",
                },
                {
                  match: {
                    id: "commAmount",
                  },
                  id: "dots",
                },
              ]}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -35,
                legend: "Transaction Date",
                legendPosition: "middle",
                legendOffset: 82,
              }}
              enableLabel={false}
              axisLeft={null}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              onClick={(bar) => {
                const indv = sortedChartDatas[bar.index];

                setCurrentTooltipData(indv);
              }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                e.id + ": " + e.formattedValue + " in country: " + e.indexValue
              }
            />
          </Box>
          <Box sx={{ width: "15%", ml: 2 }}>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
              Tx Details
            </Typography>
            <Box
              sx={{
                bgcolor: "#F1F8E9",
                height: 55,
                p: 1,
              }}
            >
              <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                Tx Amount
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                {currentTooltipData?.txAmount || "0"}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#FFF8E1",
                height: 55,
                p: 1,
              }}
            >
              <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                Eligable Amount
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                {currentTooltipData?.elegibleAmount}
              </Typography>
            </Box>

            <Box
              sx={{
                bgcolor: "#E1F5FE",
                height: 55,
                p: 1,
              }}
            >
              <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                Comm Amnt
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                {currentTooltipData?.commAmount}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#EDE7F6",
                height: 55,
                p: 1,
              }}
            >
              <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                Tx Date
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                {currentTooltipData?.txDate?.split(" ")?.[0] || ""}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#F1F8E9",
                height: 55,
                p: 1,
              }}
            >
              <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                Comm %
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                {currentTooltipData?.currentCommPercentage}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#FFF8E1",
                height: 55,
                p: 1,
              }}
            >
              <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                Months / Elig %
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                {currentTooltipData?.yearsPassed} /{" "}
                {(
                  ((currentTooltipData?.elegibleAmount || 0) /
                    (currentTooltipData?.txAmount || 1)) *
                  100
                ).toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#E1F5FE",
                height: 85,
                p: 1,
              }}
            >
              <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                Description
              </Typography>
              <Typography
                sx={{
                  fontSize:
                    currentTooltipData?.description?.length < 30 ? 14 : 12,
                  fontWeight: "bold",
                }}
              >
                {currentTooltipData?.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
}
