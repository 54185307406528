import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Grid, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

import Backdrop from "@mui/material/Backdrop";
import SubscriptionHistoryChart from "../Components/SubscriptionHistoryChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const ZOHO = window.ZOHO;

const containerStyle = {
  width: "100%",
  height: "450px",
};
export default function RelatedList() {
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const [entity, setEntity] = useState();
  const [entityId, setEntityId] = useState();
  const [apiKey, setApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [chartData, setChartData] = useState();
  const [subscription, setSubscription] = useState();
  const [commission, setCommission] = useState();
  const [transaction, setTransaction] = useState();
  const [latestEligablePercentage, setLatestEligablePercentage] = useState(0);
  const [latestCommPercentage, setLatestCommPercentage] = useState(0);
  const [totalTxCount, setTotalTxCount] = useState(0);
  const [totalTxAmount, setTotalTxAmount] = useState(0);
  const [totalCommCount, setTotalCommCount] = useState(0);
  const [totalCommAmount, setTotalCommAmount] = useState(0);
  const [currentTooltipData, setCurrentTooltipData] = useState();

  useEffect(() => {
    setLoading(true);
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        ZOHO.CRM.UI.Resize({ height: "500", width: "1200" });
        setEntity(data?.Entity);
        setEntityId(data?.EntityId);
        setLoading(false);
      });
      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  useEffect(() => {
    if (zohoLoaded) {
      /**
       * Business Logic
       */
    }
  }, [zohoLoaded]);
  if (loading || !entity || !entityId)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        Loading..
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    );

  return (
    <SubscriptionHistoryChart data={{ Entity: entity, EntityId: entityId }} />
  );
}
