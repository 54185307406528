import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";

import Backdrop from "@mui/material/Backdrop";

import addressTabIcon from "../addressTabIcon.png";
import Overview from "../Components/Overview";

const ZOHO = window.ZOHO;

export default function SettingsRoute() {
  const [authLoadingZohoUS, setAuthLoadingZohoUS] = useState(false);
  const [revokeLoadingZohoUS, setRevokeLoadingZohoUS] = useState(false);
  const [zohoAuthUS, setZohoAuthUS] = useState(false);

  const [authLoadingZohoAU, setAuthLoadingZohoAU] = useState(false);
  const [revokeLoadingZohoAU, setRevokeLoadingZohoAU] = useState(false);
  const [zohoAuthAU, setZohoAuthAU] = useState(false);

  const [authLoadingZohoEU, setAuthLoadingZohoEU] = useState(false);
  const [revokeLoadingZohoEU, setRevokeLoadingZohoEU] = useState(false);
  const [zohoAuthEU, setZohoAuthEU] = useState(false);

  const [authLoadingZohoIN, setAuthLoadingZohoIN] = useState(false);
  const [revokeLoadingZohoIN, setRevokeLoadingZohoIN] = useState(false);
  const [zohoAuthIN, setZohoAuthIN] = useState(false);

  const [authLoadingZohoJP, setAuthLoadingZohoJP] = useState(false);
  const [revokeLoadingZohoJP, setRevokeLoadingZohoJP] = useState(false);
  const [zohoAuthJP, setZohoAuthJP] = useState(false);

  const [snackbarMsg, setSnackbarMsg] = useState("");

  const [snackBarForError, setSnackBarForError] = useState(false);

  const [tabNumber, setTabNumber] = useState(0);
  const [zohoLoaded, setZohoLoaded] = useState(false);

  const [loading, setLoading] = useState(true);

  const [apiKey, setApiKey] = useState();

  const [orgId, setOrgId] = useState();
  const [connSchemas, setConnSchemas] = useState({});
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [zuid, setZuid] = useState(null);
  const [checkPoint, setCheckPoint] = useState();
  const [historicalFetchDate, setHistoricalFetchDate] = useState();

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const tabText = {
    weight: 500,
    size: "16px",
    lineHeight: "24px",
    letterSpacing: "1%",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.38)",
  };

  const handleAuthCheckZoho = async ({ connDetails }) => {
    // for zoho auth verify
    // setAuthLoadingZoho(true);
    const authCheck = await axios({
      method: "post",
      data: connDetails,
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/verifystoreauth`,
    });

    console.log(authCheck?.data?.length);
    if (authCheck?.data?.length) {
      return true;
    } else {
      // setAuthLoadingZoho(false);
      return false;
    }
  };

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        const currentEnv = await ZOHO.CRM.CONFIG.GetCurrentEnvironment();
        const dataCenterMap = {
          US: "https://www.zohoapis.com",
          EU: "https://www.zohoapis.eu",
          AU: "https://www.zohoapis.com.au",
          IN: "https://www.zohoapis.in",
          China: "https://www.zohoapis.com.cn",
          JP: "https://www.zohoapis.jp",
        };

        setDataCenterUrl(dataCenterMap?.[currentEnv?.deployment] || "");

        const dataCenterData = {
          apiname:
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL",
          value: dataCenterMap?.[currentEnv?.deployment] || "",
        };
        await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", dataCenterData);
        setZohoLoaded(true);
      });
      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  useEffect(() => {
    if (zohoLoaded) {
      // fetch previous saved data for external service and zoho crm
      fetchOrgVariablesData();
    }
  }, [zohoLoaded]);

  const handleAuthenticateZoho = async (dataCenter = "US") => {
    console.log({ dataCenter });
    const itemToStateMap = {
      US: { auth: setZohoAuthUS, loading: setAuthLoadingZohoUS },
      AU: { auth: setZohoAuthAU, loading: setAuthLoadingZohoAU },
      EU: { auth: setZohoAuthEU, loading: setAuthLoadingZohoEU },
      IN: { auth: setZohoAuthIN, loading: setAuthLoadingZohoIN },
      JP: { auth: setZohoAuthJP, loading: setAuthLoadingZohoJP },
    };
    const setAuthLoad = itemToStateMap[dataCenter].loading;

    // console.log(itemToStateMap[dataCenter].loading);

    setAuthLoad(true);
    const storeDataCenterUrlMap = {
      US: "https://www.zohoapis.com",
      EU: "https://www.zohoapis.eu",
      AU: "https://www.zohoapis.com.au",
      IN: "https://www.zohoapis.in",
      China: "https://www.zohoapis.com.cn",
      JP: "https://www.zohoapis.jp",
    };
    // setAuthLoadingZoho(true);

    let scopes = [
      "ZohoPayments.subscriptions.READ",
      "ZohoPayments.transactions.READ",
      "ZohoPayments.leads.READ",
      "ZohoPayments.commissions.READ",
      "ZohoPayments.fullaccess.READ",
    ];
    console.log({
      orgId: orgId,
      apiKey: apiKey,
      zapiKey: zapiKey,
      accountsUrl:
        "https://accounts.zoho." +
        storeDataCenterUrlMap[dataCenter]?.split("https://www.zohoapis.")?.[1],
      connName: process.env.REACT_APP_EXTENSION_IDENTIFIER + "__" + dataCenter,
      scope: scopes,
      redirectUrl: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/storecallback`,
      crmApiDomain: storeDataCenterUrlMap[dataCenter],
      zuid: zuid,
      standaloneUrl:
        process.env.REACT_APP_SANDBOX === "true"
          ? "https://plugin-easystoretracker.zohosandbox.com/crm/v2/functions/easystoretracker__zoho_auth_callback/actions/execute?auth_type=apikey&zapikey=1001.d8c9461adbe6404a6a75adb538530cad.103554dd33424d3ec758705c09dd58c7"
          : dataCenterUrl +
            "/crm/v2/functions/easystoretracker__zoho_auth_callback/actions/execute?auth_type=apikey&zapikey=" +
            zapiKey,
    });

    const authUrlResp = await axios.request({
      url:
        process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/storeauthenticate",
      method: "POST",
      data: {
        orgId: orgId,
        apiKey: apiKey,
        zapiKey: zapiKey,
        accountsUrl:
          "https://accounts.zoho." +
          storeDataCenterUrlMap[dataCenter]?.split(
            "https://www.zohoapis."
          )?.[1],
        connName:
          process.env.REACT_APP_EXTENSION_IDENTIFIER + "__" + dataCenter,
        scope: scopes,
        redirectUrl: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/storecallback`,
        crmApiDomain: storeDataCenterUrlMap[dataCenter],
        zuid: zuid,
        standaloneUrl:
          process.env.REACT_APP_SANDBOX === "true"
            ? "https://plugin-easystoretracker.zohosandbox.com/crm/v2/functions/easystoretracker__zoho_auth_callback/actions/execute?auth_type=apikey&zapikey=1001.d8c9461adbe6404a6a75adb538530cad.103554dd33424d3ec758705c09dd58c7"
            : dataCenterUrl +
              "/crm/v2/functions/easystoretracker__zoho_auth_callback/actions/execute?auth_type=apikey&zapikey=" +
              zapiKey,
      },
    });

    const myAuthCheckInterval = setInterval(async () => {
      const orgData = {
        apiKeys: [
          process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Connection_Schemas",
        ],
      };
      const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);
      console.log({ orgVariables });
      const connSchemasMap = JSON.parse(orgVariables?.Success?.Content || "{}");

      const authCheckStatus = await handleAuthCheckZoho({
        connDetails: {
          ...(connSchemasMap?.["easystoretracker__" + dataCenter] || {}),
          accountsurl: storeDataCenterUrlMap[dataCenter],
        },
      });
      const setAuthResult = itemToStateMap[dataCenter].auth;

      if (authCheckStatus) {
        setConnSchemas(connSchemasMap);
        // setTabNumber(1);
        setAuthResult(true);
        setAuthLoad(false);
        clearInterval(myAuthCheckInterval);
      }
    }, 5000);

    const newwindow = window.open(
      authUrlResp?.data?.authUrl,
      "windowName",
      "height=640,width=1024"
    );
    if (window.focus) {
      newwindow.focus();
    }
  };

  const handleRevokeZoho = async (dataCenter = "US") => {
    // setRevokeLoadingZoho(true);
    const itemToStateMap = {
      US: { auth: setZohoAuthUS, loading: setRevokeLoadingZohoUS },
      AU: { auth: setZohoAuthAU, loading: setRevokeLoadingZohoAU },
      EU: { auth: setZohoAuthEU, loading: setRevokeLoadingZohoEU },
      IN: { auth: setZohoAuthIN, loading: setRevokeLoadingZohoIN },
      JP: { auth: setZohoAuthJP, loading: setRevokeLoadingZohoJP },
    };
    const setRevLoad = itemToStateMap[dataCenter].loading;

    console.log(connSchemas["easystoretracker__" + dataCenter]);

    setRevLoad(true);
    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/storerevoke",
      method: "POST",
      data: connSchemas["easystoretracker__" + dataCenter],
    });
    console.log({ authUrlResp });
    const setAuthResult = itemToStateMap[dataCenter].auth;

    if (authUrlResp?.data) {
      // Clean the CRM Variable and set it back to CRM
      delete connSchemas["easystoretracker__" + dataCenter];
      const storeSchemaMap = {
        apiname:
          process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Connection_Schemas",
        value: connSchemas || {},
      };
      await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", storeSchemaMap);
      setAuthResult(false);
      setRevLoad(false);
    } else {
      setRevLoad(false);
    }
  };

  const fetchOrgVariablesData = async () => {
    /**
     * Get Organization Variables
     */
    const orgData = {
      apiKeys: [
        process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY",
        process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID",
        process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key",
        process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL",
        process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Connection_Schemas",
        process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Tollerance_Data",
        process.env.REACT_APP_EXTENSION_IDENTIFIER +
          "__historical_fetch_starts",
      ],
    };
    const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

    setDataCenterUrl(
      orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
      ]?.value
        ? orgVariables?.Success?.Content?.[
            `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
          ]?.value != "null"
          ? orgVariables?.Success?.Content?.[
              `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
            ]?.value
          : dataCenterUrl
        : dataCenterUrl
    );

    setHistoricalFetchDate(
      orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__historical_fetch_starts`
      ]?.value || ""
    );

    setApiKey(
      orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__API_KEY`
      ]?.value
    );

    setCheckPoint(
      JSON.parse(
        orgVariables?.Success?.Content?.[
          `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Tollerance_Data`
        ]?.value || "{}"
      )
    );

    setOrgId(
      orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Organization_ID`
      ]?.value
    );

    setZapiKey(
      orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__ZAPI_Key`
      ]?.value
    );
    const connSchemasMap = JSON.parse(
      orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Connection_Schemas`
      ]?.value || "{}"
    );
    console.log({ connSchemasMap });
    setConnSchemas(connSchemasMap);

    const headersZoho = {
      apikey:
        orgVariables?.Success?.Content?.[
          `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__API_KEY`
        ]?.value,
      connname: [`${process.env.REACT_APP_EXTENSION_IDENTIFIER}__zoho`],
      orgid:
        orgVariables?.Success?.Content?.[
          `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Organization_ID`
        ]?.value,
      // accountsurl: orgVariables?.Success?.Content
      //   ?.easygoogledriveforcrm__Data_Center_URL?.value
      //   ? orgVariables?.Success?.Content?.[
      //       `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
      //     ]?.value != "null"
      //     ? orgVariables?.Success?.Content?.[
      //         `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
      //       ]?.value
      //     : dataCenterUrl
      //   : dataCenterUrl,
      // dataCenterurlvariablename: [
      //   `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`,
      // ],
    };

    /**
     * Fetch User Details
     */
    const orgResp = await ZOHO.CRM.CONFIG.getOrgInfo();
    // console.log(
    //   "🚀 ~ file: Settings.jsx:177 ~ fetchOrgVariablesData ~ orgResp",
    //   orgResp
    // );
    setZuid(orgResp?.org?.[0]?.primary_zuid);
    // widState.zuid = orgResp?.org?.[0]?.primary_zuid;
    // console.log(
    //   "🚀 ~ file: Settings.js:999 ~ fetchOrgVariablesData ~ widState",
    //   JSON.stringify(widState)
    // );

    const itemToStateMap = {
      US: setZohoAuthUS,
      AU: setZohoAuthAU,
      EU: setZohoAuthEU,
      IN: setZohoAuthIN,
      JP: setZohoAuthJP,
    };

    const dataCenterMap = {
      US: "https://www.zohoapis.com",
      EU: "https://www.zohoapis.eu",
      AU: "https://www.zohoapis.com.au",
      IN: "https://www.zohoapis.in",
      JP: "https://www.zohoapis.jp",
    };
    const accountsUrlMap = {
      US: "https://accounts.zoho.com",
      EU: "https://accounts.zoho.eu",
      AU: "https://accounts.zoho.au",
      IN: "https://accounts.zoho.in",
      JP: "https://accounts.zoho.jp",
    };
    let centerList = Object.keys(dataCenterMap);

    for (const item of centerList) {
      const authCheckStatusZoho = await handleAuthCheckZoho({
        connDetails: {
          ...(connSchemasMap?.["easystoretracker__" + item] || {}),
          accountsurl: dataCenterMap[item],
        },
      });

      if (authCheckStatusZoho) {
        const setAuth = itemToStateMap[item];
        if (setAuth) {
          setAuth(true);
        }
      }
    }
    //
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabNumber(newValue);
  };

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        Loading
      </Backdrop>
    );
  }

  const ButtonPassedToOverviewComponent = (
    <Button
      variant="contained"
      style={{ textTransform: "capitalize" }}
      disabled={historicalFetchDate !== formatDate(new Date()) ? false : true}
      onClick={async () => {
        const tolleranceData = {
          apiname:
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Tollerance_Data",
          value: JSON.stringify(checkPoint),
        };
        await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", tolleranceData);

        const scheduleDate = {
          apiname:
            process.env.REACT_APP_EXTENSION_IDENTIFIER +
            "__historical_fetch_starts",
          value: formatDate(new Date()),
        };
        setHistoricalFetchDate(formatDate(new Date()));
        await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", scheduleDate);

        const resp = await axios.request({
          url: `https://api.v1.admin.easy-pluginz.com/utils/cronjobs`,
          method: "POST",
          headers: {
            orgid: orgId, // Org ID
            apikey: apiKey, // API KEy
            connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          },
          data: {
            reqUrl: encodeURIComponent(
              process.env.REACT_APP_SANDBOX === "true"
                ? `https://plugin-easystoretracker.zohosandbox.com/crm/v2/functions/easystoretracker__process_historical_data/actions/execute?auth_type=apikey&zapikey=1001.d8c9461adbe6404a6a75adb538530cad.103554dd33424d3ec758705c09dd58c7`
                : dataCenterUrl +
                    "/crm/v2/functions/easystoretracker__process_historical_data/actions/execute?auth_type=apikey&zapikey=" +
                    zapiKey
            ),
            reqParams: {},
            reqHeaders: {},
            reqBody: {},
            per_page: 50,
            reqType: "GET",
          },
        });
      }}
    >
      Save and Fetch Historical Data
    </Button>
  );

  return (
    <>
      <Snackbar
        open={snackBarForError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }

          setSnackBarForError(false);
        }}
      >
        <MuiAlert
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setSnackBarForError(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
          {/* You might be entered a wrong API KEY. */}
        </MuiAlert>
      </Snackbar>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "36px",
          mb: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "31.31px", height: "30.97px" }}
            src={addressTabIcon}
            alt="Logo"
          />
        </Box>

        <Typography
          sx={{
            maxWidth: "300px",
            height: "39px",
            ml: "12.48px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "25.6311px",
            lineHeight: "38px",
            textAlign: "center",
            letterSpacing: "-0.015em",
            textTransform: "capitalize",
            color: "#1E3056",
          }}
          variant="h5"
        >
          Extension Test Platform
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          borderBottom: "1px solid rgba(232, 232, 232, 1)",
        }}
      >
        <Overview
          passedButton={ButtonPassedToOverviewComponent}
          handleRevokeZoho={handleRevokeZoho}
          handleAuthenticateZoho={handleAuthenticateZoho}
          revokeLoadingZohoUS={revokeLoadingZohoUS}
          authLoadingZohoUS={authLoadingZohoUS}
          zohoAuthUS={zohoAuthUS}
          revokeLoadingZohoAU={revokeLoadingZohoAU}
          authLoadingZohoAU={authLoadingZohoAU}
          zohoAuthAU={zohoAuthAU}
          revokeLoadingZohoEU={revokeLoadingZohoEU}
          authLoadingZohoEU={authLoadingZohoEU}
          zohoAuthEU={zohoAuthEU}
          revokeLoadingZohoIN={revokeLoadingZohoIN}
          authLoadingZohoIN={authLoadingZohoIN}
          zohoAuthIN={zohoAuthIN}
          revokeLoadingZohoJP={revokeLoadingZohoJP}
          authLoadingZohoJP={authLoadingZohoJP}
          zohoAuthJP={zohoAuthJP}
          checkPoint={checkPoint}
          setCheckPoint={setCheckPoint}
        />
      </Box>

      {/* <Box sx={{ display: "flex", justifyContent: "right", pr: "10%" }}> */}
      {/* {historicalFetchDate !== formatDate(new Date()) && ( */}
      {/* <Button
          variant="contained"
          style={{ textTransform: "capitalize" }}
          disabled={
            historicalFetchDate !== formatDate(new Date()) ? false : true
          }
          onClick={async () => {
            console.log({ fromClick: checkPoint });
            const tolleranceData = {
              apiname:
                process.env.REACT_APP_EXTENSION_IDENTIFIER +
                "__Tollerance_Data",
              value: JSON.stringify(checkPoint),
            };
            await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", tolleranceData);

            const scheduleDate = {
              apiname:
                process.env.REACT_APP_EXTENSION_IDENTIFIER +
                "__historical_fetch_starts",
              value: formatDate(new Date()),
            };
            setHistoricalFetchDate(formatDate(new Date()));
            await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", scheduleDate);

            const resp = await axios.request({
              url: `https://api.v1.admin.easy-pluginz.com/utils/cronjobs`,
              method: "POST",
              headers: {
                orgid: orgId, // Org ID
                apikey: apiKey, // API KEy
                connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
              },
              data: {
                reqUrl: encodeURIComponent(
                  process.env.REACT_APP_SANDBOX === "true"
                    ? `https://plugin-easystoretracker.zohosandbox.com/crm/v2/functions/easystoretracker__process_historical_data/actions/execute?auth_type=apikey&zapikey=1001.d8c9461adbe6404a6a75adb538530cad.103554dd33424d3ec758705c09dd58c7`
                    : dataCenterUrl +
                        "/crm/v2/functions/easystoretracker__process_historical_data/actions/execute?auth_type=apikey&zapikey=" +
                        zapiKey
                ),
                reqParams: {},
                reqHeaders: {},
                reqBody: {},
                per_page: 50,
                reqType: "GET",
              },
            });
          }}
        >
          Save and Fetch Historical Data
        </Button> */}
      {/* )} */}
      {/* </Box> */}
    </>
  );
}
