import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Settings from "./Pages/Settings";
import Buttons from "./Pages/Buttons";
import RelatedList from "./Pages/RelatedList";
import CustomAction from "./Pages/CustomAction";
import "./App.css";
import Dashboard from "./Pages/Dashboard";

const router = createBrowserRouter([
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/relatedlist",
    element: <RelatedList />,
  },
  {
    path: "/buttons",
    element: <Buttons />,
  },
  {
    path: "/button",
    element: <Buttons />,
  },
  {
    path: "/customaction",
    element: <CustomAction />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
