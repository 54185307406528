import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SubscriptionHistoryChart from "./SubscriptionHistoryChart";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "easystoretracker__Transaction_ID",
    disablePadding: true,
    label: "Transaction ID",
    align: "left",
  },
  {
    id: "easystoretracker__Customer_Company_Name",
    disablePadding: true,
    label: "Company Name",
    align: "left",
  },
  {
    id: "easystoretracker__Transaction_Amount",
    disablePadding: false,
    label: "Tx Amount",
    align: "right",
  },
  {
    id: "easystoretracker__Transaction_Date",
    disablePadding: false,
    label: "Tx Date",
    align: "left",
  },
  {
    id: "easystoretracker__Eligible",
    disablePadding: false,
    label: "Elig %",
    align: "right",
  },
  {
    id: "easystoretracker__Commission_Amount",
    disablePadding: false,
    label: "Comm Amount",
    align: "right",
  },
  {
    id: "easystoretracker__Comm",
    disablePadding: false,
    label: "Comm %",
    align: "right",
  },
  {
    id: "easystoretracker__Check_Points",
    disablePadding: false,
    label: "Issues",
    align: "left",
  },
];

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              px:
                headCell.id === "easystoretracker__Check_Points" ||
                headCell.id === "easystoretracker__Transaction_Date"
                  ? 0
                  : null,
            }}
            align={headCell.align === "right" ? "right" : "left"}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row, setAllIssueTransactions, allIssueTransactions, transactionUrl } =
    props;
  const [open, setOpen] = React.useState(false);
  // console.log(row.easystoretracker__Check_Points[0]);
  const storeLocationMap = {
    US: "https://store.zoho.com",
    AU: "https://store.zoho.com.au",
    EU: "https://store.zoho.eu",
    IN: "https://store.zoho.in",
    JP: "https://store.zoho.jp",
    CN: "https://store.zoho.cn",
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <a
            href={transactionUrl + "/" + row.id}
            target="_blank"
            rel="noreferrer"
          >
            {row?.Name}
          </a>
        </TableCell>
        <TableCell component="th" scope="row">
          <a
            href={`${
              storeLocationMap?.[row?.easystoretracker__Store_Location]
            }/CustomerHistory.do?profileid=${row.easystoretracker__Profile_ID}`}
            target="_blank"
            rel="noreferrer"
          >
            {row?.easystoretracker__Customer_Company_Name}
          </a>
        </TableCell>
        <TableCell align="right">
          {row.easystoretracker__Transaction_Amount}
        </TableCell>
        <TableCell sx={{ px: 0 }}>
          {row.easystoretracker__Transaction_Date_Time}
        </TableCell>
        <TableCell align="right">{row.easystoretracker__Eligible}</TableCell>
        <TableCell align="right">
          {row.easystoretracker__Commission_Amount}
        </TableCell>
        <TableCell align="right">{row.easystoretracker__Comm}</TableCell>
        <TableCell sx={{ px: 0 }}>
          {row.easystoretracker__Check_Points}
        </TableCell>
        <TableCell sx={{ pl: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open && (
              <SubscriptionHistoryChart
                data={{
                  Entity: "easystoretracker__Store_Transactions",
                  EntityId: row.id,
                }}
                setAllIssueTransactions={setAllIssueTransactions}
                allIssueTransactions={allIssueTransactions}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function IssueTransactionTable({
  allIssueTransactions,
  setAllIssueTransactions,
  transactionUrl,
}) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState(
    "easystoretracker__Transaction_Date"
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    // console.log({"property":property})
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - allIssueTransactions.length)
      : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(allIssueTransactions, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, allIssueTransactions]
  );

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"small"}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {visibleRows.map((row, index) => (
              <Row
                transactionUrl={transactionUrl}
                key={row.id}
                row={row}
                setAllIssueTransactions={setAllIssueTransactions}
                allIssueTransactions={allIssueTransactions}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 40]}
        component="div"
        count={allIssueTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
